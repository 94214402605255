<template>
  <div>
    <div class="sticky-bottom-navigation">
      <v-row>
        <v-col cols="12">
          <v-bottom-navigation
            :value="value"
            color="none"
            active-class="no-highlight"
            horizontal
            :style="
              $vuetify.breakpoint.width < 600
                ? 'height: 10vh; '
                : 'height: 12vh'
            "
          >
            <v-btn
              class="custom-btn btn--medium"
              @click="goToAnotherPage('line')"
              value="line"
            >
              <span class="custom-text">
                <v-img
                  class="mx-auto mt-n2 img-line"
                  src="../assets/logo/Line-Logo.png"
                ></v-img>

                <!-- <v-icon class="custom-icon">mdi-magnify</v-icon> <br />{{
                  $t("message.checklottery")
                }} -->
              </span>
            </v-btn>

            <v-btn
              class="custom-btn btn--medium"
              @click="goToAnotherPage('คำสั่งซื้อ')"
              value="คำสั่งซื้อ"
            >
              <span class="custom-text"
                ><v-icon class="custom-icon">mdi-reload</v-icon>
                <br />
                {{ $t("message.purchasehistory") }}
              </span>
            </v-btn>

            <v-btn
              class="custom-btn btn--medium"
              @click="goToAnotherPage('เลือกซื้อ')"
              value="เลือกซื้อ"
            >
              <span class="custom-text"
                ><v-icon class="custom-icon">mdi-cart</v-icon> <br />
                {{ $t("message.shoplottery") }}
              </span>
            </v-btn>

            <v-btn
              class="custom-btn btn--medium"
              @click="goToAnotherPage('ตู้เซฟ')"
              value="ตู้เซฟ"
            >
              <span class="custom-text">
                <v-icon class="custom-icon">mdi-shopping</v-icon> <br />
                {{ $t("message.mywallet") }}
              </span>
            </v-btn>

            <v-btn
              class="custom-btn btn--medium"
              @click="goToAnotherPage('ข้อมูลส่วนตัว')"
              value="ข้อมูลส่วนตัว"
            >
              <span class="custom-text"
                ><v-icon class="custom-icon">mdi-account</v-icon> <br />
                {{ $t("message.profile") }}
              </span>
            </v-btn>
          </v-bottom-navigation>
        </v-col>
      </v-row>
    </div>

    <DialogLogin :dialog="dialogLogin" @closeDialogLogin="closeDialogLogin" />
  </div>
</template>

<script>
import { Encode, Decode } from "@/services";
import DialogLogin from "./Dialog/DialogLogin.vue";

export default {
  components: {
    DialogLogin,
  },

  data: () => ({
    value: null,

    dialogLogin: false,

    userData: {},
  }),

  watch: {
    $route(to) {
      // if (to.path == "/") {
      //   this.value = "ตรวจหวย";
      //
      // }
      if (to.path == "/") {
        this.value = "เลือกซื้อ";
      }
      if (to.path == "/basket") {
        this.value = "ตะกร้า";
      }
    },
  },

  created() {
    const encodedUserLogin = localStorage.getItem("MUAYLEKUser");
    if (encodedUserLogin !== null) {
      const decodedUserLogin = Decode.decode(encodedUserLogin);
      this.userData = JSON.parse(decodedUserLogin);
    }
  },

  methods: {
    async checkPrivacyNa() {
      await this.$swal.fire({
        icon: "warning",
        html: "ระบบกำลังทำการปรับปรุง",
        showConfirmButton: true,
        // timer: 1500,
      });
    },

    closeDialogLogin() {
      this.dialogLogin = false;
    },

    goToAnotherPage(item) {
      if (item == "line") {
        window.open("https://line.me/ti/p/~@muaylotto", "_blank");
      }
      if (item == "คำสั่งซื้อ") {
        Object.keys(this.userData).length !== 0
          ? this.$router.currentRoute.path == "/order"
            ? ""
            : this.$router.push({
                path: "/order",
              })
          : (this.dialogLogin = true);
      }
      if (item == "เลือกซื้อ") {
        Object.keys(this.userData).length !== 0
          ? this.$router.currentRoute.path == "/"
            ? ""
            : this.$router.push({
                path: "/",
              })
          : (this.dialogLogin = true);
      }
      if (item == "ตู้เซฟ") {
        Object.keys(this.userData).length !== 0
          ? this.$router.currentRoute.path == "/safe"
            ? ""
            : this.$router.push({
                path: "/safe",
              })
          : (this.dialogLogin = true);
      }
      if (item == "ข้อมูลส่วนตัว") {
        Object.keys(this.userData).length !== 0
          ? this.$router.currentRoute.path == "/profile"
            ? ""
            : this.$router.push({
                path: "/profile",
              })
          : (this.dialogLogin = true);
      }
      this.value = item;
    },
  },
};
</script>

<style scoped>
.no-highlight {
  font-weight: normal !important;
}

.sticky-card {
  height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.sticky-bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
}

.v-item-group.v-bottom-navigation {
  justify-content: center;
}

.custom-btn {
  flex: 1;
  text-align: center;
}

.custom-text {
  font-size: 2em; /* Initial font size using em units */
}

.custom-icon {
  font-size: 2em; /* Initial icon size using em units */
}

.img-line {
  width: 80px;
  height: 80px;
}

/* Media queries for responsiveness */
@media screen and (max-width: 680px) {
  .img-line {
    width: 40px;
    height: 40px;
  }
  .sticky-card {
    height: 30px;
    position: fixed;
    bottom: 0vh; /* Adjust the value based on your layout */
    left: 0;
    right: 0;
    z-index: 1000; /* Ensure the card is above the bottom navigation */
  }
  .sticky-bottom-navigation {
    position: fixed;
    bottom: 0vh;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
  }

  .custom-text {
    font-size: 0.9em; /* Adjust font size for smaller screens */
  }

  .v-btn:not(.v-btn--round).v-size--default {
    min-width: 64px;
    padding: 0 3px;
  }
  .v-item-group.v-bottom-navigation[data-v-40ab164b] {
    justify-content: center;
  }

  /* Adjust the size of buttons for smaller screens */
  .btn--medium {
    min-width: 30px;
  }
}
</style>
